import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { API_HOST, GOOGLE_MAPS_API_KEY } from './Constants';

function ShowMountain(props) {
  const { name } = useParams();
  const [mountain, setMountain] = useState(undefined);
  const [climbedDate, setClimbedDate] = useState('');

  useEffect(() => {
    if (!props.user) {
      setMountain(undefined);
      return;
    }

    (async () => {
      const res = await fetch(`${API_HOST}/mountains/${name}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${props.user}`,
        },
      });
      setMountain(await res.json());
    })();
  }, [props.user, name]);

  function handleChangeClimbedDate(e) {
    e.preventDefault();
    setClimbedDate(e.target.value);
  }

  async function handleClickRegisterRecord(e) {
    e.preventDefault()

    // Validate input
    if (climbedDate === '') {
      return;
    }

    let form = new FormData();
    form.append('climbed_date', climbedDate);
    form.append('mountain_name', name);
    await fetch(`${API_HOST}/records`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${props.user}`
      },
      body: form
    });

    // Clear input form
    setClimbedDate('');

    // Add record to the view
    setMountain({
      ...mountain,
      climbed_dates: mountain.climbed_dates.concat([climbedDate]),
    });
  }

  async function handleClickDeleteRecord(e, climbedDate) {
    e.preventDefault();

    let form = new FormData();
    form.append('climbed_date', climbedDate);
    form.append('mountain_name', name);
    await fetch(`${API_HOST}/records`, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${props.user}`
      },
      body: form
    });

    // Delete record from the view
    setMountain({
      ...mountain,
      climbed_dates: mountain.climbed_dates.filter(d => d !== climbedDate)
    });
  }

  if (!props.user) {
    return null;
  }

  if (mountain === undefined) {
    return 'Loading...';
  }

  return (
    <div style={{ marginBottom: '60px' }}>
      <h2>{mountain.name}</h2>

      <div style={{ marginBottom: '40px' }}>
        <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
          <GoogleMap
            mapContainerStyle={{ maxWidth: '800px', height: '400px' }}
            center={{ lat: mountain.latitude, lng: mountain.longitude }}
            zoom={10}
          >
            <Marker
              position={{ lat: mountain.latitude, lng: mountain.longitude }}
            ></Marker>
          </GoogleMap>
        </LoadScript>
      </div>

      <table className="App-mountain">
        <tbody>
          <tr>
            <th scope="row">山域</th>
            <td >{mountain.area}</td>
          </tr>
          <tr>
            <th scope="row">標高</th>
            <td>{mountain.altitude.toLocaleString()}m</td>
          </tr>
          <tr>
            <th scope="row">緯度、経度</th>
            <td>{mountain.latitude}, {mountain.longitude}</td>
          </tr>
          <tr>
            <th scope="row">都道府県</th>
            <td>{mountain.prefectures.join(', ')}</td>
          </tr>
          <tr>
            <th scope="row">名数</th>
            <td>{mountain.titles.map((title, index) => {
            return (
              <Link key={index} to={`/titles/${title}`} style={{ marginRight: '4px' }}>
                {title}
              </Link>
            );
          })}</td>
          </tr>
        </tbody>
      </table>

      <div style={{ display: "flex", marginBottom: "20px" }}>
        <input
          type="date"
          onChange={handleChangeClimbedDate}
          value={climbedDate}
          style={{ marginRight: "10px" }}
        />
        <button onClick={handleClickRegisterRecord}>登頂を記録する</button>
      </div>

      <table style={{ textAlign: 'left' }}>
        <thead>
          <tr>
            <th>登頂履歴</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {mountain.climbed_dates.sort().map((climbedDate, index) => {
            return (
              <tr key={index}>
                <td>{climbedDate}</td>
                <td><button onClick={(e) => handleClickDeleteRecord(e, climbedDate)}>Delete</button></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ShowMountain;