import './App.css';
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Link,
  Switch,
  Route
} from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signInWithRedirect, getRedirectResult, signOut, GoogleAuthProvider } from "firebase/auth";
import ListTitles from './ListTitles';
import ListMountains from './ListMountains';
import ListMountainsHistory from './ListMountainsHistory';
import ShowMountain from './ShowMountain';
import { NODE_ENV, IDENTITY_TOOLKIT_API_KEY } from './Constants';

const firebaseConfig = {
  apiKey: IDENTITY_TOOLKIT_API_KEY,
  authDomain: "yamakiroku-b6ac4.firebaseapp.com",
  projectId: "yamakiroku-b6ac4",
  storageBucket: "yamakiroku-b6ac4.appspot.com",
  messagingSenderId: "789593383615",
  appId: "1:789593383615:web:fdae54d32fda2c5de3dc0a",
  measurementId: "G-RDF544J22Y"
};

initializeApp(firebaseConfig);

function App() {
  const [user, setUser] = useState(undefined);
  const [isUserLoading, setIsUserLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();

    getRedirectResult(auth)
      .then((result) => {
        if (result && result.user) {
          result.user.getIdToken().then((idToken) => {
            setUser(idToken);
            setIsUserLoading(false);
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const idToken = await user.getIdToken();
          setUser(idToken);

          if (NODE_ENV === 'development') {
            console.log(idToken);
          }
        } catch (error) {
          console.error(error);
        }
      }

      setIsUserLoading(false);
    });
  }, []);

  // Handler functions

  function handleClickLogin() {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    try {
      signInWithRedirect(auth, provider);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleClickLogout() {
    const auth = getAuth();
    await signOut(auth);
    setUser(undefined);
  }

  // Application

  return (
    <Router>
      <header className="App-header">
        <Link className="App-brand" to='/'>
          <h1>Yamakiroku</h1>
        </Link>

        <span className="App-header-spacer"></span>

        {isUserLoading ?
          <span>Loading...</span> :
          user === undefined ?
            <span className="link" onClick={handleClickLogin}>Login</span> :
            <React.Fragment>
              <span onClick={handleClickLogout} className="link App-header-item">Logout</span>
            </React.Fragment>
        }
      </header>

      <Switch>
        <Route exact path='/' children={<ListTitles user={user} />}></Route>
        <Route exact path='/titles/:name' children={<ListMountains user={user} />}></Route>
        <Route exact path='/titles/:name/history' children={<ListMountainsHistory user={user} />}></Route>
        <Route exact path='/mountains/:name' children={<ShowMountain user={user} />}></Route>
      </Switch>
    </Router>
  );
}

export default App;
