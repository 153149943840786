import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { API_HOST } from './Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortNumericDown, faSortNumericUpAlt } from '@fortawesome/free-solid-svg-icons';

function applyif(obj, cond, fn) {
  return cond ? fn(obj) : obj;
}

function removeDuplicateMountain(records) {
  return records.reduce((acc, record) => {
    if (!acc.find((r => r.name === record.name))) {
      acc.push(record);
    }
    return acc;
  }, []);
}

function ListMountainsHistory(props) {
  const { name } = useParams();
  const [records, setRecords] = useState([]);
  const [isDateAscending, setIsDateAscending] = useState(true);
  const [showOnlyFirstClimb, setShowOnlyFirstClimb] = useState(true);

  useEffect(() => {
    if (!props.user) {
      setRecords([]);
      return;
    }

    (async () => {
      const res = await fetch(`${API_HOST}/titles/${name}/history`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${props.user}`,
        }
      });
      const records = await res.json();
      setRecords(records);
    })();
  }, [props.user, name]);

  if (!props.user) {
    return null;
  }

  if (records.length === 0) {
    return 'Loading...';
  }

  function handleSwitchDateOrder() {
    setIsDateAscending(!isDateAscending);
  }

  function handleChangeFirstClimb() {
    setShowOnlyFirstClimb(!showOnlyFirstClimb);
  }

  return (
    <div style={{ marginBottom: '60px' }}>

      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <h2>{name}</h2>
        <span style={{ flexGrow: 1 }}></span>
        <div style={{ cursor: 'pointer' }} onClick={handleSwitchDateOrder}>
          <FontAwesomeIcon icon={isDateAscending ? faSortNumericUpAlt : faSortNumericDown} /> 日付
        </div>
        <span style={{ margin: '4px' }}></span>
        <div><label><input onChange={handleChangeFirstClimb} type="checkbox" checked={showOnlyFirstClimb} />初登頂</label></div>
      </div>

      <div style={{ marginBottom: '18px' }}>
        <Link to={`/titles/${name}`}>一覧</Link>
      </div>

      <table className="App-titles">
        <tbody>
          {
            applyif(
              applyif(records, showOnlyFirstClimb, (arr) => removeDuplicateMountain(arr)).map((record, index) => Object.assign(record, {order: index + 1})),
              isDateAscending,
              (arr) => arr.slice().reverse()
            ).map((record, index, records) => {
                const year = records[index].climbed_date.split('-')[0];

                if (index === 0 || records[index - 1].climbed_date.split('-')[0] !== year) {
                  return (
                    <React.Fragment key={index}>
                      <tr key={index} style={{ fontWeight: '800', height: '40px' }}>
                        <td colSpan="4" style={{ verticalAlign: 'bottom', paddingBottom: '8px' }}>{year}</td>
                      </tr>
                      <tr>
                        <td>{record.order}</td>
                        <td>{record.climbed_date}</td>
                        <td><Link to={`/mountains/${record.name}`}>{record.name}</Link></td>
                      </tr>
                    </React.Fragment>
                  );
                }

                return (
                  <tr key={index}>
                    <td>{record.order}</td>
                    <td>{record.climbed_date}</td>
                    <td><Link to={`/mountains/${record.name}`}>{record.name}</Link></td>
                  </tr>
                );
              })
            }
        </tbody>
      </table>
    </div>
  );
}

export default ListMountainsHistory;