import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { API_HOST, GOOGLE_MAPS_API_KEY } from './Constants';

function ListMountains(props) {
  const { name } = useParams();
  const [mountains, setMountains] = useState([]);

  useEffect(() => {
    if (!props.user) {
      setMountains([]);
      return;
    }

    (async () => {
      const res = await fetch(`${API_HOST}/mountains?title=${name}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${props.user}`,
        }
      });
      setMountains(await res.json());
    })();
  }, [props.user, name]);

  if (!props.user) {
    return null;
  }

  if (mountains.length === 0) {
    return 'Loading...';
  }

  return (
    <div style={{ marginBottom: '60px' }}>

      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <h2>{name} ({mountains.filter(mountain => mountain.climbed_count > 0).length}/{mountains.length})</h2>
        <span style={{ margin: '4px' }}></span>
      </div>

      <div style={{ marginBottom: '40px' }}>
        <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
          <GoogleMap
            mapContainerStyle={{ maxWidth: '800px', height: '400px' }}
            center={{ lat: 35.0, lng: 135.0 }}
            zoom={5}
            onLoad={(map) => {
              const maxLatitude = mountains.reduce((maxLatitude, m) => Math.max(maxLatitude, m.latitude), -90);
              const minLatitude = mountains.reduce((minLatitude, m) => Math.min(minLatitude, m.latitude), 90);
              const maxLongitude = mountains.reduce((maxLongitude, m) => Math.max(maxLongitude, m.longitude), -180);
              const minLongitude = mountains.reduce((minLongitude, m) => Math.min(minLongitude, m.longitude), 180);
              const bounds = new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(minLatitude, minLongitude),
                new window.google.maps.LatLng(maxLatitude, maxLongitude)
              );
              map.fitBounds(bounds);
            }}
          >
            {mountains.map((mountain, index) => {
              return (
                <Marker
                  key={index}
                  position={{ lat: mountain.latitude, lng: mountain.longitude }}
                  label={{ color: 'white', text: (index + 1).toString() }}
                  icon={{
                    path: 'M-1,0 A1,1 0,1,0 1,0 A1,1 0,1,0 -1,0',
                    scale: 10,
                    fillColor: '#444b6e',
                    fillOpacity: mountain.climbed_count === 0 ? 0.5 : 1.0,
                    strokeOpacity: 0
                  }}
                ></Marker>
              );
            })}
          </GoogleMap>
        </LoadScript>
      </div>

      <div style={{ marginBottom: '18px' }}>
        <Link to={`/titles/${name}/history`}>履歴</Link>
      </div>

      <table className="App-titles">
        <thead>
          <tr>
            <th>#</th>
            <th>山域</th>
            <th>名前</th>
            <th>標高</th>
            <th>都道府県</th>
            <th>登頂</th>
          </tr>
        </thead>
        <tbody>
          {mountains.map((mountain, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{mountain.area}</td>
                <td><Link to={`/mountains/${mountain.name}`}>{mountain.name}</Link></td>
                <td>{mountain.altitude.toLocaleString()} m</td>
                <td>{mountain.prefecture_names.join(', ')}</td>
                <td>{mountain.climbed_count === 0 ? '-' : '✔️'}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ListMountains;