import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API_HOST } from './Constants';

function ListTitles(props) {
  const [titles, setTitles] = useState([]);

  useEffect(() => {
    if (!props.user) {
      setTitles([]);
      return;
    }

    (async () => {
      const res = await fetch(`${API_HOST}/titles`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${props.user}`,
        }
      });
      setTitles(await res.json());
    })();
  }, [props.user]);

  if (!props.user) {
    return null;
  }

  if (titles.length === 0 && props.user) {
    return 'Loading...';
  }

  return (
    <div>
      <table style={{ width: '100%' }}>
        <tbody>
          {titles.sort().map((title, index) => {
            return (
              <tr key={index}>
                <td>{title.name}</td>
                <td><Link to={`/titles/${title.name}`}>一覧</Link></td>
                <td><Link to={`/titles/${title.name}/history`}>履歴</Link></td>
                <td style={{ textAlign: 'right' }}>{title.climbed_mountains} / {title.total_mountains}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ListTitles;